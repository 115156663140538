
.loader__container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 200px;
}

.circle {
  margin-left: 5px;
  height: 10px;
  width: 10px;
  background: #da291c;
  border-radius: 10px;
  transform-origin: center;
}

.one {
  animation: puls 1000ms cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.two {
  animation: puls 1000ms cubic-bezier(0.215, 0.61, 0.355, 1) 100ms infinite;
}

.three {
  animation: puls 1000ms cubic-bezier(0.215, 0.61, 0.355, 1) 200ms infinite;
}

@keyframes puls {
  0% {
    transform: scale(1) translateY(10px);
    height: 10px;
  }
  50% {
    transform: scale(0.5) translateY(-30px);
    height: 40px;
  }
  100% {
    transform: scale(1) translateY(15px);
  }
}
