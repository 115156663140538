form {
  background: white;
  padding: 40px;
}
.form__footer_buttons {
    margin-bottom: 15px;
}
.form__content {
  padding: 23px 0px;
}

.del__btn {
  float: right;
  margin: -2.8rem;
  padding-right: 19px;
}
.form__notification {
  padding: 15px 30px;
  margin: 14px 0px;
}
.next__btn {
  float: right;
}

input.form__checkbox {
  margin-right: 6px;
}

.add__btn.margin {
  margin: -25px 0px 20px 0px;
}
.columns.step2__content {
  margin-left: 5px;
}
button.button.is-small.is-primary.add {
    margin: -10px 0px 14px 0px;
}

.primary {
  color: #9e9e9e;
  font-weight: bold;
  padding: 5px 0px;
}
