form.user-validation {
    padding: 0px;
    margin: 15px 0px;
}

.primaryLabel {
  color: #9e9e9e;
  display: inline;
}

.primaryLabel:hover {
  color: #9e9e9e;
  display: inline;
}
.lost__code {
  margin-bottom: -25px;
  position: relative;
  float: right;
  z-index: 10001;
}
