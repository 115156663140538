.Features {
  max-width: 900px;
  margin: 80px auto 0 auto;
}

.Features__columns {
  // Reverse every other row
  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  &:not(:last-of-type) {
    padding-bottom: 1.5rem;
    @media screen and (min-width: 769px) {
      padding-bottom: 2.5rem;
    }
  }
}

.Features__title {
  margin-bottom: 1.2rem !important;
}

.Features__no-classname {
  display: block;
  max-width: 300px;
  margin: 30px auto;
}
