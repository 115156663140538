.FooterComponent {
  padding: 1.5rem 1.5rem;
  background-color: #00d1b2;
  color: #FFF;

  .container {
    display: flex;
    flex-wrap: wrap;
    > div {
      display: flex;
      flex: none;
      justify-content: center;
      width: 100%;
      margin: 8px 8px;
    }
  }

  a {
    color: inherit;
    &:hover {
      color: #ffffff;
      text-decoration: underline;
    }
  }

  .brand {
    img {
      display: block;
      height: 32px;
    }
  }

  .social {
    align-items: flex-end;
    span:not(:first-of-type) {
      margin-left: 30px;
    }
  }

  .links {
    span:not(:first-of-type) {
      margin-left: 15px;
    }
  }

  .copyright {
  }

  .footer__nav {
    flex: 100%;
  }
  .footer__nav_item {
    list-style: none;
    padding: 13px;
  }
  // Tablet and up
  @media screen and (min-width: 769px) {
    .container {

      .left {

      }

      .right {

      }
    }

    // Move links to end (bottom right)
    // Swaps position with social
    .links {
      order: 1;
    }
  }
}
li.footer__item {
  padding: 15px;
  display: inline;
  text-align: center;
  @media screen and (max-width: 700px) {
    display: inherit;
  }
}

.linkedin {
  margin-top: -35px;
  position: absolute;
}
