.MainSection__header {
  // We're using custom style instead of the
  // .section-header class because we want less bottom
  // padding and for text alignment to be responsive
  // and controlled by parent element
  margin-bottom: 2rem;
}

.subtitle {
  color: #9e9e9e;
}
.MainSection__subtitle {
  max-width: 700px;
  margin: 0 auto;
}

.MainSection__image {
  max-width: 600px;
  margin: 0 auto;
}


.hero.has-background {
  position: relative;
  overflow: hidden;
}
.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.hero-background.is-transparent {
  opacity: 0.3;
}


img.perulogo {
    padding: 10px;
}
