.Pricing__column {
  display: flex;
  align-items: stretch;

  // Override Bulma .column
  // Space column contents out a bit more
  // for tablet and up.
  @media screen and (min-width: 769px) {
    padding: 1.5rem;
  }
}

.Pricing__card {
  display: flex;
  // Stretch to fit column width
  width: 100%;
  // Ensure .card-content stretches to fit width
  align-items: stretch;

  &.emphasized {
    background-color: #00d1b2;
    color: #fff;
  }
}

.Pricing__card-content {
  // Flex so that button can position self at
  // bottom of card using margin-top auto.
  display: flex;
  flex-direction: column;
  // Stretch to fit column width
  width: 100%;
}

.Pricing__period {
  font-size: 1.25rem;
}

.Pricing__price {
  margin: 20px 0;
}

.Pricing__price-symbol {
  opacity: 0.4;
}

.Pricing__price-month {
  opacity: 0.4;
}

.Pricing__description {
  padding-bottom: 40px;
  color: #9e9e9e;
}

.faqItem {
  color: #9e9e9e;
  list-style: circle;
  padding: 1px 7px;
}

.primaryPricing {
  padding-bottom: 40px;
  color: #fff;
}

.Pricing__button {
  margin-top: auto;
}

.termscondtions {
  padding: 40px;
}

.termscondtions__header {
  color: #4A4A4A;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0px;
}

.termscondtions__text {
  color: #4A4A4A;
  line-height: 30px;
}

.logo {
  padding: 10px 0px;
}
